import React from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(zoomPlugin);
ChartJS.defaults.scale.grid.color = '#FFFFFF40';
ChartJS.defaults.scale.ticks.color = '#A0A0A0';
ChartJS.defaults.scale.title.color = '#FFFFFF';
ChartJS.defaults.plugins.legend.labels.color = '#FFFFFF';
ChartJS.defaults.plugins.title.color = '#FFFFFF';
ChartJS.defaults.animation.duration = 0;

const satsOnFirstShow = false;

const BORDER_COLORS = [
    // chart.js default colors
    'rgb(054, 162, 235)', // blue
    'rgb(255, 099, 132)', // red
    'rgb(255, 159, 064)', // orange
    'rgb(255, 205, 086)', // yellow
    'rgb(075, 192, 192)', // green
    'rgb(153, 102, 255)', // purple
    'rgb(201, 203, 207)', // grey
    // symmetry colors
    'rgb(255, 051, 051)', // red
    'rgb(255, 153, 051)', // orange
    'rgb(051, 204, 051)', // green
];
const BACKGROUND_COLORS = BORDER_COLORS.map((color) => color.replace('rgb(', 'rgba(').replace(')', ', 0.5)'));


export function getHRcolor() {
    return BORDER_COLORS[1];
}


const footfallOptions = {
    minPos: 10,
    maxPos: 90,
    stepSize: 10,
    borderWidth: 15,
    rh: {
        pos: 20,
        label: 'RH',
        colour: '#ffc07e',
    },
    lh: {
        pos: 40,
        label: 'LH',
        colour: '#eca3cf',
    },
    rf: {
        pos: 60,
        label: 'RF',
        colour: '#87bae8',
    },
    lf: {
        pos: 80,
        label: 'LF',
        colour: '#a4ca62',
    },
};


export function getFootfallOptions() {
    return footfallOptions;
}


export function formatTime(time) {
    var absTime = Math.abs(time);
    var hours = Math.floor(absTime / 60 / 60);
    var minutes = Math.floor(absTime / 60 % 60);
    var seconds = Math.floor(absTime % 60);
    var withHour = hours > 0;
    if (!withHour)
        minutes = minutes + (hours * 60);

    var result = '';
    if (time < 0)
        result += '-';
    if (withHour)
        result += hours + ':';
    result += (minutes < 10 ? '0' : '') + minutes + ':';
    result += (seconds < 10 ? '0' : '') + seconds;
    return result;
}


function formatLocalTime(time) {
    if (time <= 1)
        return '';
    return new Date(time).toLocaleTimeString('nl', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
}


export function averageSignal(signal, seconds) {
    if (!signal || signal.length === 0)
        return signal;

    var signalDuration = (signal[signal.length - 1][0] - signal[0][0]);
    if (signalDuration === 0)
        return signal;
    const samplesPerSecond = Math.round(signal.length / signalDuration);
    var avgWindow = seconds * samplesPerSecond;
    if (avgWindow <= 1)
        avgWindow = 1;

    var result = [];

    for (var i = 0; i < signal.length; ++i) {
        // 1 value very second
        if (avgWindow >= seconds && i % samplesPerSecond !== 0)
            continue;

        var sum = 0;
        var count = 0;
        var start = Math.floor(i - avgWindow / 2);
        var end = Math.floor(i + avgWindow / 2);

        for (var j = start; j < end; ++j) {
            if (j < 0 || j >= signal.length)
                continue;

            if (Math.abs(signal[i][0] - signal[j][0]) > seconds)
                continue;

            var val = signal[j][1];
            if (isNaN(val) || val === null)
                continue;

            sum += val;
            count++;
        }

        var avg = count > 0 ? (sum / count) : null;
        result.push([signal[i][0], avg]);
    }

    return result;
}


function onZoomPan({ chart }) {
    for (const c of Object.values(ChartJS.instances)) {
        if (c.id === chart.id)
            continue;
        if (chart.scales.x.min === c.scales.x.min && chart.scales.x.max === c.scales.x.max)
            continue;

        c.zoomScale(
            'x',
            {
                min: chart.scales.x.min,
                max: chart.scales.x.max,
            },
            'none'
        );
    }
}


export function getZoomScale() {
    var result = {
        min: undefined,
        max: undefined,
    };

    for (const c of Object.values(ChartJS.instances)) {
        result.min = c?.scales?.x?.min;
        result.max = c?.scales?.x?.max;
        break;
    }

    return result;
}


export function getScaleWidth(position) {
    return calcAxisWidth(position) + 'px';
}


function calcAxisWidth(axis) {
    var onlyPosition = typeof axis === 'string' || axis instanceof String;
    var position = onlyPosition ? axis : axis.position;

    // determine number total and shown axes of each chart
    var totalAxes = new Map();
    var shownAxes = new Map();
    for (const c of Object.values(ChartJS.instances)) {
        var totalCount = 0;
        var showCount = 0;
        for (const scale of Object.values(c.config.options.scales)) {
            if (scale.position === position) {
                totalCount++;
                if (scale.display)
                    showCount++;
            }
        }
        totalAxes.set(c.id, totalCount);
        shownAxes.set(c.id, showCount);
    }

    const defaultWidth = 50;
    var finalWidth = defaultWidth * (shownAxes.size > 0 ? Math.max(...shownAxes.values()) : 0);

    if (!onlyPosition) {
        axis.width = finalWidth;
        if (!axis.chart.options.scales[axis.id].display) {
            // hidden axis
            axis.width = 0;
            // if all axes are hidden, divide the expected width over all hidden axes
            if (!shownAxes.get(axis.chart.id) && totalAxes.get(axis.chart.id) > 0)
                axis.width = finalWidth / totalAxes.get(axis.chart.id);
        }
        else if (shownAxes.get(axis.chart.id) > 0) {
            // divide the expected width over the shown axes
            axis.width = finalWidth / shownAxes.get(axis.chart.id);
        }
    }

    return finalWidth;
}


function getTooltipLabel(context, xLabelFn) {
    var xVal = xLabelFn(context.parsed.x, context) || '';
    var yVal = '';
    if (!isNaN(context.parsed.y) && context.parsed.y !== null)
        yVal = ' - ' + context.parsed.y.toFixed(2);
    if (context.dataset.label.includes('marker'))
        yVal = '';
    return context.dataset.label + ': ' + xVal + yVal;
}


function createChartObject(datasets, yScales, xLabelFn, enableZoomPan) {
    datasets.push(
        {
            label: 'width marker',
            data: [],
            showLine: true,
            pointRadius: 0,
            yAxisID: 'yMarker',
            backgroundColor: BACKGROUND_COLORS[6],
            borderColor: BORDER_COLORS[6],
        },
        {
            label: 'left marker',
            data: [],
            showLine: true,
            pointRadius: 0,
            yAxisID: 'yMarker',
            backgroundColor: BACKGROUND_COLORS[6],
            borderColor: BORDER_COLORS[6],
        },
        {
            label: 'right marker',
            data: [],
            showLine: true,
            pointRadius: 0,
            yAxisID: 'yMarker',
            backgroundColor: BACKGROUND_COLORS[6],
            borderColor: BORDER_COLORS[6],
        },
    );

    var result = {
        created: false,
        reference: React.createRef(),
        maxTime: null,
        chartWidth: 60,

        type: 'scatter',
        data: {
            datasets,
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            animations: null,
            interaction: {
                intersect: false,
                mode: 'nearest',
            },
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        filter: item => !item.text.includes('marker'),
                    },
                    onClick: function (event, legendItem) {
                        event.chart.data.datasets[legendItem.datasetIndex].hidden = !event.chart.data.datasets[legendItem.datasetIndex].hidden;
                        var y_axis_id = event.chart.data.datasets[legendItem.datasetIndex].yAxisID;
                        var showAxis = false;
                        for (const ds of event.chart.data.datasets) {
                            if (y_axis_id === ds.yAxisID)
                                showAxis ||= !ds.hidden;
                        }
                        event.chart.options.scales[y_axis_id].display = showAxis;
                        if (event.chart.options.scaleChangeFn)
                            event.chart.options.scaleChangeFn();

                        var showGrid = true;
                        for (const axis of Object.values(event.chart.options.scales)) {
                            if (axis.axis === 'x')
                                continue;
                            if (axis.display) {
                                axis.grid.drawOnChartArea = showGrid;
                                showGrid = false;
                            } else {
                                axis.grid.drawOnChartArea = false;
                            }
                        }

                        for (const c of Object.values(ChartJS.instances)) {
                            c.update();
                        }
                    },
                },
                tooltip: {
                    enabled: true,
                    bodyFont: {
                        size: 16,
                    },
                    callbacks: {
                        label: (context) => getTooltipLabel(context, xLabelFn),
                    },
                },
                zoom: {
                    // limits: {
                    //     x: {
                    //         min: 'original',
                    //         max: 'original',
                    //     },
                    // },
                    zoom: {
                        wheel: {
                            enabled: enableZoomPan,
                        },
                        pinch: {
                            enabled: enableZoomPan
                        },
                        mode: 'x',
                        onZoom: onZoomPan,
                    },
                    pan: {
                        enabled: enableZoomPan,
                        mode: 'x',
                        onPan: onZoomPan,
                    }
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        callback: xLabelFn,
                    },
                },
                ...yScales,
                yMarker: {
                    // markers
                    min: 0,
                    max: 1,
                    position: 'right',
                    display: false,
                    afterFit: calcAxisWidth,
                },
            },
        },
    };

    return result;
}


export function createChartInfoObject(realtime = false) {
    let width, height, gradient;
    function getGradient(ctx, chartArea) {
        if (!gradient || width !== chartArea.width || height !== chartArea.height) {
            width = chartArea.width;
            height = chartArea.height;
            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0.5, BORDER_COLORS[7]);
            gradient.addColorStop(0.7, BORDER_COLORS[8]);
            gradient.addColorStop(0.9, BORDER_COLORS[9]);
        }

        return gradient;
    };

    var datasets = [
        {
            label: 'symmetry',
            data: [],
            showLine: true,
            pointRadius: 0,
            yAxisID: 'ySym',
            backgroundColor: BACKGROUND_COLORS[9],
            borderColor: function (context) {
                const { ctx, chartArea } = context.chart;
                if (!chartArea)
                    return;
                return getGradient(ctx, chartArea);
            },
        },
        {
            label: 'heart rate',
            data: [],
            showLine: true,
            pointRadius: 0,
            yAxisID: 'yHR',
            backgroundColor: BACKGROUND_COLORS[1],
            borderColor: BORDER_COLORS[1],
        },
        {
            label: 'speed',
            data: [],
            showLine: true,
            pointRadius: 0,
            yAxisID: 'ySpeed',
            backgroundColor: BACKGROUND_COLORS[0],
            borderColor: BORDER_COLORS[0],
        },
        {
            label: 'satellites',
            data: [],
            hidden: !satsOnFirstShow,
            showLine: true,
            pointRadius: 0,
            yAxisID: 'ySat',
            backgroundColor: BACKGROUND_COLORS[3],
            borderColor: BORDER_COLORS[3],
        },
        {
            label: 'altitude',
            data: [],
            hidden: true,
            showLine: true,
            pointRadius: 0,
            yAxisID: 'yAlt',
            backgroundColor: BACKGROUND_COLORS[6],
            borderColor: BORDER_COLORS[6],
        },
    ];

    var yScales = {
        yHR: {
            // heart rate
            suggestedMin: 30,
            suggestedMax: 80,
            position: 'left',
            ticks: {
                color: BORDER_COLORS[1],
            },
            title: {
                display: true,
                text: 'BPM',
                color: BORDER_COLORS[1],
            },
            grid: {
                drawOnChartArea: true,
            },
            afterFit: calcAxisWidth,
        },
        ySym: {
            // symmetry
            min: 0,
            max: 1,
            position: 'left',
            ticks: {
                color: BORDER_COLORS[9],
            },
            title: {
                display: true,
                text: 'sym. index',
                color: BORDER_COLORS[9],
            },
            grid: {
                drawOnChartArea: false,
            },
            afterFit: calcAxisWidth,
        },
        ySat: {
            // satellites
            display: satsOnFirstShow,
            suggestedMin: 0,
            suggestedMax: 20,
            position: 'left',
            ticks: {
                color: BORDER_COLORS[3],
            },
            title: {
                display: true,
                text: 'num. satellites',
                color: BORDER_COLORS[3],
            },
            grid: {
                drawOnChartArea: false,
            },
            afterFit: calcAxisWidth,
        },
        yAlt: {
            // altitude
            display: false,
            position: 'left',
            ticks: {
                color: BORDER_COLORS[6],
            },
            title: {
                display: true,
                text: 'altitude',
                color: BORDER_COLORS[6],
            },
            grid: {
                drawOnChartArea: false,
            },
            afterFit: calcAxisWidth,
        },
        ySpeed: {
            // speed / duration / length
            suggestedMin: 0,
            suggestedMax: 20,
            position: 'left',
            ticks: {
                color: BORDER_COLORS[0],
            },
            title: {
                display: true,
                text: 'km/h',
                color: BORDER_COLORS[0],
            },
            grid: {
                drawOnChartArea: false,
            },
            afterFit: calcAxisWidth,
        },
    };

    var xLabelFn = realtime ? formatLocalTime : formatTime;
    var enableZoomPan = !realtime;
    return createChartObject(datasets, yScales, xLabelFn, enableZoomPan);
}


export function createChartStrideObject(realtime = false) {
    var datasets = [
        {
            label: 'stride length',
            data: [],
            showLine: true,
            pointRadius: 0,
            yAxisID: 'yLength',
            backgroundColor: BACKGROUND_COLORS[4],
            borderColor: BORDER_COLORS[4],
        },
        {
            label: 'stride frequency',
            data: [],
            showLine: true,
            pointRadius: 0,
            yAxisID: 'yFreq',
            backgroundColor: BACKGROUND_COLORS[2],
            borderColor: BORDER_COLORS[2],
        },
    ];

    var yScales = {
        yLength: {
            // stride length
            suggestedMin: 0,
            suggestedMax: 5,
            position: 'left',
            ticks: {
                color: BORDER_COLORS[4],
            },
            title: {
                display: true,
                text: 'meter',
                color: BORDER_COLORS[4],
            },
            grid: {
                drawOnChartArea: true,
            },
            afterFit: calcAxisWidth,
        },
        yFreq: {
            // stride freq
            suggestedMin: 0,
            suggestedMax: 2,
            position: 'left',
            ticks: {
                color: BORDER_COLORS[2],
            },
            title: {
                display: true,
                text: 'stride/second',
                color: BORDER_COLORS[2],
            },
            grid: {
                drawOnChartArea: false,
            },
            afterFit: calcAxisWidth,
        },
    };

    var xLabelFn = realtime ? formatLocalTime : formatTime;
    var enableZoomPan = !realtime;
    return createChartObject(datasets, yScales, xLabelFn, enableZoomPan);
}


export function createChartFootfallsObject(realtime = false) {
    function getFootFallLabel(value) {
        if (value === footfallOptions.rh.pos)
            return footfallOptions.rh.label;
        else if (value === footfallOptions.lh.pos)
            return footfallOptions.lh.label;
        else if (value === footfallOptions.rf.pos)
            return footfallOptions.rf.label;
        else if (value === footfallOptions.lf.pos)
            return footfallOptions.lf.label;
        else
            return undefined;
    };

    var datasets = [];
    for (var i = 0; i < 4; ++i) {
        var opt = (i === 0) ? footfallOptions.rh : ((i === 1) ? footfallOptions.lh : ((i === 2) ? footfallOptions.rf : footfallOptions.lf));
        datasets.push({
            label: opt.label,
            data: [],
            yAxisID: 'yFootfalls',
            borderColor: opt.colour,
            backgroundColor: opt.colour,
            borderWidth: footfallOptions.borderWidth,
            showLine: true,
            pointRadius: 0,
            pointHoverRadius: 0,
        });
    }

    var yScales = {
        yFootfalls: {
            min: footfallOptions.minPos,
            max: footfallOptions.maxPos,
            position: 'left',
            ticks: {
                autoSkip: false,
                stepSize: footfallOptions.stepSize,
                callback: getFootFallLabel,
            },
            title: {
                display: true,
                text: 'Footfalls',
            },
            grid: {
                display: false,
            },
            afterFit: calcAxisWidth,
        },
    };

    var xLabelFn = realtime ? formatLocalTime : formatTime;
    var enableZoomPan = !realtime;
    var result = createChartObject(datasets, yScales, xLabelFn, enableZoomPan);

    var tooltip = {
        enabled: true,
        bodyFont: {
            size: 16,
        },
        filter: function (context) {
            if (context.dataset.label.includes('marker'))
                return true;

            // No tooltip for first and last block
            var ds = context.dataset.data;
            return context.dataIndex >= 1 && context.dataIndex <= (ds.length - 2);
        },
        callbacks: {
            labelColor: function (context) {
                return {
                    backgroundColor: context.dataset.backgroundColor,
                };
            },
            label: function (context) {
                if (context.dataset.label.includes('marker'))
                    return getTooltipLabel(context, xLabelFn);

                var ds = context.dataset.data;
                if (context.dataIndex > 1 && context.dataIndex < (ds.length - 2)) {
                    var isEndVal = (context.dataIndex + 1) >= ds.length || isNaN(ds[context.dataIndex + 1].y);
                    var d1 = ds[context.dataIndex + (isEndVal ? -1 : 0)];
                    var d2 = ds[context.dataIndex + (isEndVal ? 0 : 1)];
                    var t1 = d1.x - (d1.overlapFix || 0);
                    var t2 = d2.x - (d2.overlapFix || 0);
                    var t1ms = Math.round(t1 * 100).toString().slice(-2);
                    var t2ms = Math.round(t2 * 100).toString().slice(-2);
                    var duration = parseInt(Math.round((t2 - t1) * 1000));
                    return formatTime(t1) + '.' + t1ms + ' - ' + formatTime(t2) + '.' + t2ms + ' (' + duration + 'ms)';
                } else if (context.dataIndex === 1 || context.dataIndex === (ds.length - 2)) {
                    var t3 = ds[context.dataIndex].x;
                    var t3ms = Math.round(t3 * 100).toString().slice(-2);
                    return formatTime(t3) + '.' + t3ms;
                }
            },
        },
    };

    result.options.plugins.legend.display = false;
    result.options.plugins.tooltip = tooltip;

    return result;
}

export function createChartCompareObject() {
    let width, height, gradient;
    function getGradient(ctx, chartArea) {
        if (!gradient || width !== chartArea.width || height !== chartArea.height) {
            width = chartArea.width;
            height = chartArea.height;
            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0.5, BORDER_COLORS[7]);
            gradient.addColorStop(0.7, BORDER_COLORS[8]);
            gradient.addColorStop(0.9, BORDER_COLORS[9]);
        }

        return gradient;
    };

    var datasets = [
        {
            label: 'symmetry',
            data: [],
            showLine: true,
            pointRadius: 8,
            pointHoverRadius: 8,
            yAxisID: 'ySym',
            backgroundColor: BACKGROUND_COLORS[9],
            borderColor: function (context) {
                const { ctx, chartArea } = context.chart;
                if (!chartArea)
                    return;
                return getGradient(ctx, chartArea);
            },
        },
        {
            label: 'heart rate',
            data: [],
            showLine: true,
            pointRadius: 8,
            pointHoverRadius: 8,
            yAxisID: 'yHR',
            backgroundColor: BACKGROUND_COLORS[1],
            borderColor: BORDER_COLORS[1],
        },
        {
            label: 'speed',
            data: [],
            showLine: true,
            pointRadius: 8,
            pointHoverRadius: 8,
            yAxisID: 'ySpeed',
            backgroundColor: BACKGROUND_COLORS[0],
            borderColor: BORDER_COLORS[0],
        },
    ];

    var yScales = {
        yHR: {
            // heart rate
            suggestedMin: 30,
            suggestedMax: 80,
            position: 'left',
            ticks: {
                color: BORDER_COLORS[1],
            },
            title: {
                display: true,
                text: 'BPM',
                color: BORDER_COLORS[1],
            },
            grid: {
                drawOnChartArea: true,
            },
            afterFit: calcAxisWidth,
        },
        ySym: {
            // symmetry
            min: 0,
            max: 1,
            position: 'left',
            ticks: {
                color: BORDER_COLORS[9],
            },
            title: {
                color: BORDER_COLORS[9],
            },
            grid: {
                drawOnChartArea: false,
            },
            afterFit: calcAxisWidth,
        },
        ySpeed: {
            // speed / duration / length
            suggestedMin: 0,
            suggestedMax: 20,
            position: 'left',
            ticks: {
                color: BORDER_COLORS[0],
            },
            title: {
                display: true,
                text: 'km/h',
                color: BORDER_COLORS[0],
            },
            grid: {
                drawOnChartArea: false,
            },
            afterFit: calcAxisWidth,
        },
    };

    var xLabelFn = function (val, context) {
        var result = undefined;
        var chart = this?.chart || context?.chart;
        var ds = chart?.data?.datasets;
        if (!ds || ds.length === 0 || !ds[0]?.data)
            return result;

        var data = ds[0].data;
        for (var i = 0; i < data.length; ++i) {
            if (data[i].x === val && data[i].date)
                result = [new Date(data[i].date).toLocaleString(), formatTime(data[i].range[0]) + '-' + formatTime(data[i].range[1])];
        }

        return result;
    };

    var enableZoomPan = false;
    var result = createChartObject(datasets, yScales, xLabelFn, enableZoomPan);
    result.options.scales.x.ticks.autoSkip = false;
    result.options.scales.x.ticks.stepSize = 1;
    return result;
}
