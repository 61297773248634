import { BrowserRouter, Route, Routes } from 'react-router';
import Home from './home';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ChangePassword from './ChangePassword';
import System from './Inertia';
import Overview from './Overview';
import Measurements from './Measurements';
import Details from './Details';
import Horses from './Horses';
import Compare from './Compare';

import { useEffect, useState } from 'react';


export function getPages() {
    return ['Home', 'Overview', 'Systems', 'Measurements', 'Details', 'Comparison', 'Horses'];
}

export function getNavigation(target, source) {
    var page = undefined;

    switch (target) {
        case 'Home':
            page = '/';
            break;
        case 'Overview':
            page = '/overview';
            break;
        case 'Systems':
            page = '/system';
            break;
        case 'Measurements':
            page = '/measurements';
            break;
        case 'Details':
            page = '/details';
            break;
        case 'Comparison':
            page = '/compare';
            break;
        case 'Horses':
            page = '/horses';
            break;
        default:
            break;
    }

    if (page === source)
        page = undefined;

    return page;
}

function App() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [system, setSystem] = useState(undefined);
    const [measurement, setMeasurement] = useState(undefined);

    const logIn = function (success, newLogin = true, username = '') {
        if (!success || (success && newLogin)) {
            localStorage.removeItem('compareItems');
            localStorage.removeItem('detailsMeasurementID');
            localStorage.removeItem('detailsSystemID');
            localStorage.removeItem('measurementHorseFilter');
            localStorage.removeItem('selectedRoomID');
            localStorage.removeItem('systemCheckboxes');
            localStorage.removeItem('trainingTypeID');
            localStorage.removeItem('user');
        }
        setLoggedIn(success);
        setUsername(username);
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user.token) {
            logIn(false);
            return;
        }

        var apiServer = process.env.REACT_APP_API_SERVER;
        fetch(apiServer + '/auth/verify', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + user.token,
            },
        })
            .then((r) => {
                if (!r.ok)
                    throw new Error(r.status + ': ' + r.statusText);
                return r.json();
            })
            .then((r) => {
                logIn(r.message === 'success', false, user.username);
            })
            .catch(e => {
                // server offline?
            });
    }, [])

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home username={username} loggedIn={loggedIn} logIn={logIn} setSystem={setSystem} />} />
                <Route path='/signin' element={<SignIn logIn={logIn} />} />
                <Route path='/signup' element={<SignUp logIn={logIn} />} />
                <Route path='/changepassword' element={<ChangePassword username={username} logIn={logIn} />} />
                <Route path='/system' element={<System username={username} loggedIn={loggedIn} system={system} setSystem={setSystem} />} />
                <Route path='/overview' element={<Overview username={username} loggedIn={loggedIn} setSystem={setSystem} />} />
                <Route path='/measurements' element={<Measurements loggedIn={loggedIn} setMeasurement={setMeasurement} />} />
                <Route path='/compare' element={<Compare loggedIn={loggedIn} setMeasurement={setMeasurement} />} />
                <Route path='/details' element={<Details loggedIn={loggedIn} measurement={measurement} setMeasurement={setMeasurement} />} />
                <Route path='/horses' element={<Horses loggedIn={loggedIn} />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
