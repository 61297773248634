import React from 'react';
import { useNavigate } from 'react-router';
import { getPages, getNavigation } from './App';
import { Box, Button, CssBaseline, Stack, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './images/logo.png';


const Home = (props) => {
    const defaultTheme = createTheme({ palette: { mode: 'dark' } });
    const { loggedIn, username } = props;
    const navigate = useNavigate();

    const onLoginClick = () => {
        if (loggedIn) {
            props.logIn(false);
        } else {
            navigate('/signin');
        }
    }

    const onChangePasswordClick = () => {
        navigate('/changePassword');
    }

    const renderLoggedIn = () => {
        if (!loggedIn) {
            return (
                <Button variant='contained' size='large' onClick={onLoginClick}>{'Log in'}</Button>
            );
        }

        const getButtons = () => {
            var elements = [];
            for (const page of getPages()) {
                const dest = getNavigation(page, window.location.pathname);
                elements.push(<Button key={page} variant='contained' size='large' onClick={() => navigate(dest)}>{page}</Button>);
            }
            return elements;
        }

        return (
            <Box>
                <Typography variant='subtitle1' className='text-center'>
                    Welcome {username}
                </Typography>

                <Box sx={{ margin: '1rem 0 4rem 0' }}>
                    <Button variant='contained' size='large' sx={{ marginRight: '1rem' }} onClick={onLoginClick}>{'Log out'}</Button>
                    <Button variant='contained' size='large' onClick={onChangePasswordClick}>{'Change password'}</Button>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        {getButtons()}
                    </Stack>
                </Box>
            </Box>
        );
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Box
                sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80vh',
                }}
            >
                <div className='logo-gradient'>
                    <img src={logo} alt='varenne' className='main-image' />
                </div>

                {renderLoggedIn()}
            </Box>
        </ThemeProvider>
    )
}

export default Home;
