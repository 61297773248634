import React, { Component } from 'react';
import { Navigate } from 'react-router';
import { getSocket, socketConnected } from './Socket';
import { formatTime } from './Chart';
import { getPages, getNavigation } from './App';
import { Box, Button, CssBaseline, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Drawer, FormControlLabel, List, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText, Divider, ListSubheader, Stack } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Comment as CommentIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';


class Measurements extends Component {
    constructor(props) {
        super(props);

        this.defaultTheme = createTheme({ palette: { mode: 'dark' } });

        // allow to use 'this' to access class members
        this.connectHandler = this.connectHandler.bind(this);
        this.measurementHandler = this.measurementHandler.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.horseHandler = this.horseHandler.bind(this);

        this.state = {
            isConnected: socketConnected(),
            navigate: undefined,
            drawerOpen: false,
            measurements: [],
            users: [],
            horses: [],
            trainingTypes: [],
            commentID: null,
            removeItem: undefined,
            showRemovedMeasurements: parseInt(localStorage.getItem('measurementsShowRemoved')) ? true : false,
        }
    }

    componentDidMount() {
        var socket = getSocket();
        if (socket) {
            socket.on('connect', this.connectHandler);
            socket.on('disconnect', this.connectHandler);
            socket.on('measurements', this.measurementHandler);
            socket.on('users', this.userHandler);
            socket.on('horses', this.horseHandler);

            this.getUsers();
            this.getHorses();
            this.getTrainingTypes();
            this.getMeasurements();
        }
    }

    componentWillUnmount() {
        var socket = getSocket();
        if (socket) {
            socket.off('connect', this.connectHandler);
            socket.off('disconnect', this.connectHandler);
            socket.off('measurements', this.measurementHandler);
            socket.off('users', this.userHandler);
            socket.off('horses', this.horseHandler);
        }
    }

    getMeasurements() {
        var socket = getSocket();
        socket.emit('measurements', 'request', 'get', { includeRemoved: true });
    }

    getUsers() {
        var socket = getSocket();
        socket.emit('users', 'request', 'get');
    }

    getHorses() {
        var socket = getSocket();
        socket.emit('horses', 'request', 'get');
    }

    getTrainingTypes() {
        var socket = getSocket();
        socket.emit('measurements', 'request', 'getTrainingTypes');
    }

    connectHandler(reason) {
        this.setState({ isConnected: socketConnected() });

        if (reason === 'io server disconnect')
            this.getUsers();
    }

    measurementHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ measurements: data });
            }
            else if (subChannel === 'getTrainingTypes') {
                this.setState({ trainingTypes: data });
            }
            else {
                this.getMeasurements();
            }
        }
    }

    userHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ users: data });
            }
        }
    }

    horseHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ horses: data });
            }
            else {
                this.getHorses();
            }
        }
    }

    renderMeasurementTable() {
        const columns = [
            {
                field: 'date',
                headerName: 'Date',
                type: 'date',
                sortable: true,
                width: 220,
                filterable: false,
                getApplyQuickFilterFn: undefined,
                renderCell: (params) => {
                    return <Box className='DataGrid-cell'>{params.value.toLocaleString()}</Box>;
                },
            },
            {
                field: 'name',
                headerName: 'Horse',
                sortable: true,
                width: 200,
                valueGetter: (value, row) => {
                    return row.systems;
                },
                getSortComparator: (sortDirection) => {
                    const modifier = sortDirection === 'desc' ? -1 : 1;
                    return (v1, v2) => {
                        var comp1 = undefined;
                        if (v1 && v1.length > 0)
                            comp1 = this.state.horses.find((item) => item.horseID === v1[0].horseID)?.name;
                        var comp2 = undefined;
                        if (v2 && v2.length > 0)
                            comp2 = this.state.horses.find((item) => item.horseID === v2[0].horseID)?.name;
                        if (!comp1)
                            return 1;
                        else if (!comp2)
                            return -1;
                        else
                            return modifier * comp1.localeCompare(comp2);
                    };
                },
                filterable: true,
                getApplyQuickFilterFn: (value) => {
                    return (systems) => {
                        var match = false;
                        if (!value)
                            return match;
                        for (const system of systems) {
                            if (system.removed && !this.state.showRemovedMeasurements)
                                continue;
                            var username = this.state.users.find((item) => item.userID === system.userID)?.username;
                            if (username)
                                match ||= username.toLowerCase().includes(value.toLowerCase());
                            var horse = this.state.horses.find((item) => item.horseID === system.horseID);
                            if (horse?.name)
                                match ||= horse.name.toLowerCase().includes(value.toLowerCase());
                            if (horse?.stables)
                                match ||= horse.stables.toLowerCase().includes(value.toLowerCase());
                            if (horse?.owner)
                                match ||= horse.owner.toLowerCase().includes(value.toLowerCase());
                        }
                        return match;
                    };
                },
                renderCell: (params) => {
                    var elements = [];
                    var index = 0;
                    for (const system of params.value) {
                        if (system.removed && !this.state.showRemovedMeasurements)
                            continue;

                        var horse = this.state.horses.find((item) => item.horseID === system.horseID);
                        var horseName = horse?.name || '\u00a0';
                        var horseDetails = '';
                        if (horse?.stables || horse?.owner) {
                            if (horse?.stables)
                                horseDetails += horse.stables + (horse.owner ? ', ' : '');
                            if (horse?.owner)
                                horseDetails += horse.owner;
                        }
                        var username = this.state.users.find((item) => item.userID === system.userID)?.username || 'unknown';
                        elements.push(
                            <Box className='DataGrid-sub-cell' key={index++}>
                                <Box color='text.primary' sx={{ fontSize: '1rem' }} key={index++}>{horseName}</Box>
                                <Box color='text.secondary' sx={{ fontSize: '0.75rem' }} key={index++}>{horseDetails}</Box>
                                <Box color='text.secondary' sx={{ fontSize: '0.75rem' }} key={index++}>{username}</Box>
                            </Box>);
                    }
                    return <Box className='DataGrid-cell'>{elements}</Box>;
                }
            },
            {
                field: 'training',
                headerName: 'Training Type',
                sortable: false,
                width: 120,
                filterable: true,
                getApplyQuickFilterFn: (value) => {
                    return (systems) => {
                        var match = false;
                        if (!value)
                            return match;
                        for (const system of systems) {
                            if (system.removed && !this.state.showRemovedMeasurements)
                                continue;
                            var trainingTypeName = this.state.trainingTypes.find((item) => item.trainingTypeID === system.trainingTypeID)?.name || '';
                            if (trainingTypeName)
                                match ||= trainingTypeName.toLowerCase().includes(value.toLowerCase());
                        }
                        return match;
                    };
                },
                valueGetter: (value, row) => {
                    return row.systems;
                },
                renderCell: (params) => {
                    var elements = [];
                    var index = 0;
                    for (const system of params.value) {
                        if (system.removed && !this.state.showRemovedMeasurements)
                            continue;
                        var trainingTypeName = this.state.trainingTypes.find((item) => item.trainingTypeID === system.trainingTypeID)?.name || '';
                        elements.push(
                            <Box className='DataGrid-sub-cell' key={index++}>{trainingTypeName}</Box>
                        );
                    }
                    return <Box className='DataGrid-cell'>{elements}</Box>;
                },
            },
            {
                field: 'duration',
                headerName: 'Duration',
                sortable: false,
                width: 100,
                filterable: false,
                getApplyQuickFilterFn: undefined,
                valueGetter: (value, row) => {
                    return row.systems;
                },
                renderCell: (params) => {
                    var elements = [];
                    var index = 0;
                    for (const system of params.value) {
                        if (system.removed && !this.state.showRemovedMeasurements)
                            continue;
                        var duration = system.duration > 0 ? formatTime(system.duration) : '';
                        elements.push(
                            <Box className='DataGrid-sub-cell' key={index++}>{duration}</Box>
                        );
                    }
                    return <Box className='DataGrid-cell'>{elements}</Box>;
                },
            },
            {
                field: 'comments',
                headerName: 'Comments',
                sortable: false,
                width: 130,
                filterable: false,
                getApplyQuickFilterFn: undefined,
                valueGetter: (value, row) => {
                    return row.systems;
                },
                renderCell: (params) => {
                    var commentCount = 0;
                    for (const system of params.value) {
                        if (system.removed && !this.state.showRemovedMeasurements)
                            continue;
                        commentCount += (system.comments?.length || 0);
                    }

                    const handleClickOpen = () => {
                        this.setState({ commentID: params.id });
                    };

                    if (commentCount === 0)
                        return '';

                    return (
                        <Box className='DataGrid-cell'>
                            <Button variant='outlined' onClick={handleClickOpen}>
                                {'Show (' + commentCount + ')'}
                            </Button>
                        </Box>
                    );
                }
            },
            {
                field: 'files',
                headerName: 'Files (imu, hr, results)',
                sortable: false,
                width: 175,
                filterable: false,
                getApplyQuickFilterFn: undefined,
                valueGetter: (value, row) => {
                    return row.systems;
                },
                renderCell: (params) => {
                    var elements = [];
                    var index = 0;
                    for (const system of params.value) {
                        if (system.removed && !this.state.showRemovedMeasurements)
                            continue;
                        elements.push(
                            <Box className='DataGrid-sub-cell' key={index++}>
                                <Checkbox disabled checked={system?.files?.inertia?.filename ? true : false} key={index++} />
                                <Checkbox disabled checked={system?.files?.polar?.filename ? true : false} key={index++} />
                                <Checkbox disabled checked={system?.files?.results?.filename ? true : false} key={index++} />
                            </Box>
                        );
                    }
                    return <Box className='DataGrid-cell'>{elements}</Box>;
                }
            },
            {
                field: 'segments',
                headerName: 'Segments',
                sortable: false,
                width: 100,
                filterable: false,
                getApplyQuickFilterFn: undefined,
                valueGetter: (value, row) => {
                    return row.systems;
                },
                renderCell: (params) => {
                    var elements = [];
                    var index = 0;
                    for (const system of params.value) {
                        if (system.removed && !this.state.showRemovedMeasurements)
                            continue;
                        elements.push(
                            <Box className='DataGrid-sub-cell' key={index++}>{system.segments?.length || ''}</Box>
                        );
                    }
                    return <Box className='DataGrid-cell'>{elements}</Box>;
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                sortable: false,
                width: 300,
                filterable: false,
                getApplyQuickFilterFn: undefined,
                renderCell: (params) => {
                    var removeButtons = [];
                    var detailsButtons = [];
                    var index = 0;

                    const addSystemAction = (system) => {
                        var isRemoved = system ? system.removed : (this.state.measurements.find((meas) => meas.measurementID === params.id)?.removed);
                        if (isRemoved && !this.state.showRemovedMeasurements)
                            return;

                        const onRemoveMeasurement = () => {
                            this.setState({
                                removeItem: {
                                    measurementID: params.id,
                                    systemID: system?.userID,
                                    removed: isRemoved,
                                },
                            });
                        };

                        const onSelectMeasurement = () => {
                            this.props.setMeasurement({ measurementID: params.id, systemID: system?.userID });
                            this.setState({ navigate: '/details' });
                        };

                        removeButtons.push(
                            <Box className='DataGrid-sub-cell' key={index++}>
                                <Button focusRipple variant='outlined' onClick={onRemoveMeasurement} key={index++}>{isRemoved ? 'Unhide' : 'Hide'}</Button>
                            </Box>
                        );

                        detailsButtons.push(
                            <Box className='DataGrid-sub-cell' key={index++}>
                                <Button focusRipple variant='contained' disabled={!system || isRemoved} onClick={onSelectMeasurement} key={index++}>Details</Button>
                            </Box>
                        );
                    }

                    for (const system of params.row.systems)
                        addSystemAction(system);
                    if (params.row.systems.length === 0)
                        addSystemAction(undefined);

                    return (
                        <Box className='DataGrid-cell'>
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Box className='flex-col' sx={{ gap: 0 }}>
                                    {detailsButtons}
                                </Box>
                                <Box className='flex-col' sx={{ gap: 0 }}>
                                    {removeButtons}
                                </Box>
                            </Stack>
                        </Box>
                    );
                }
            },
        ];

        var rows = [];
        for (const measurement of this.state.measurements) {
            if (measurement.removed && !this.state.showRemovedMeasurements)
                continue;

            measurement.systems.sort((a, b) => {
                var name1 = this.state.users.find((item) => item.userID === a.userID)?.username || 'unknown';
                var name2 = this.state.users.find((item) => item.userID === b.userID)?.username || 'unknown';
                return name1.localeCompare(name2);
            });

            rows.push({
                id: measurement.measurementID,
                date: new Date(measurement.date),
                systems: measurement.systems,
            });
        }

        const getCommentsList = () => {
            var listContent = [];
            var listIndex = 0;

            var measurement = this.state.measurements.find((meas) => meas.measurementID === this.state.commentID);
            if (measurement?.systems) {
                for (const system of measurement.systems) {
                    var elements = [];
                    var listItemIndex = 0;

                    for (const comment of system.comments) {
                        if (elements.length > 0)
                            elements.push(
                                <Divider
                                    key={listItemIndex++}
                                    variant='inset'
                                    component='li'
                                />
                            );

                        elements.push(
                            <ListItem
                                key={listItemIndex++}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <CommentIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ whiteSpace: 'pre-line' }}
                                    primary={
                                        <Stack
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 2,
                                            }}
                                        >
                                            <Box color='text.primary'>
                                                {this.state.users.find((item) => item.userID === comment.userID)?.username || 'unknown'}
                                            </Box>
                                            <Box sx={{ marginLeft: 'auto' }}>
                                                {new Date(comment.date).toLocaleString()}
                                            </Box>
                                        </Stack>
                                    }
                                    secondary={comment.comment}
                                />
                            </ListItem>
                        );
                    }

                    var listSubHeader = undefined;
                    if (measurement?.systems?.length > 1) {
                        var horseName = this.state.horses.find((item) => item.horseID === system.horseID)?.name;
                        if (!horseName)
                            horseName = this.state.users.find((item) => item.userID === system.userID)?.username || 'unknown';

                        listSubHeader = (
                            <ListSubheader sx={{ color: 'text.primary', bgcolor: 'inherit', fontWeight: 'bold' }}>
                                {horseName}
                            </ListSubheader>
                        );
                    }

                    var systemList = (
                        <List
                            key={listIndex++}
                            sx={{ width: '100%' }}
                            subheader={listSubHeader}
                        >
                            {elements}
                        </List>
                    );
                    listContent.push(systemList);
                }
            }

            return listContent;
        }

        const handleCommentClose = () => {
            this.setState({ commentID: null });
        };

        const setPaginationModel = (data) => {
            if (data) {
                localStorage.setItem('measurementsPageSize', data.pageSize);
            }
        };

        const setSortModel = (data) => {
            if (data && data.length > 0) {
                localStorage.setItem('measurementsSortField', data[0].field);
                localStorage.setItem('measurementsSortDirection', data[0].sort);
            }
        };

        const setFilterModel = (data) => {
            localStorage.setItem('measurementHorseFilter', JSON.stringify(data.quickFilterValues));
        };

        const handleRemoveClose = () => {
            this.setState({ removeItem: undefined });
        };

        const onMeasurementRemove = (event) => {
            event.preventDefault();

            var socket = getSocket();
            socket.emit('measurements', 'request', 'remove', {
                measurementID: this.state.removeItem.measurementID,
                systemID: this.state.removeItem.systemID,
                removed: !this.state.removeItem.removed,
            });
            handleRemoveClose();
        };

        var pageSizeOptions = [5, 10, 25, 50, 100];
        var pageSize = parseInt(localStorage.getItem('measurementsPageSize')) || 10;
        if (!pageSizeOptions.includes(pageSize))
            pageSize = pageSizeOptions[1];

        var filterValues = [];
        try {
            filterValues = JSON.parse(localStorage.getItem('measurementHorseFilter'));
        }
        catch (err) { }
        if (!Array.isArray(filterValues))
            filterValues = [];

        function QuickSearchToolbar() {
            return (
                <Box
                    sx={{
                        padding: '0.5rem 0.5rem 0rem 0.5rem',
                        display: 'flex',
                    }}
                >
                    <GridToolbarQuickFilter
                        style={{ flex: 1 }}
                        quickFilterParser={(searchInput) => [searchInput]}
                    />
                </Box>
            );
        }

        return (
            <Box sx={{ width: '100%' }}>
                <Dialog
                    open={this.state.commentID !== null}
                    onClose={handleCommentClose}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogContent dividers>
                        <DialogContentText component='div'>
                            {getCommentsList()}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCommentClose}>Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.removeItem !== undefined}
                    onClose={handleRemoveClose}
                >
                    <DialogContent>
                        {'Are you sure you want to ' + (this.state.removeItem?.removed ? 'unhide' : 'hide') + ' this measurement?'}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRemoveClose}>Cancel</Button>
                        <Button onClick={onMeasurementRemove}>{this.state.removeItem?.removed ? 'Unhide' : 'Hide'}</Button>
                    </DialogActions>
                </Dialog>

                <DataGrid
                    ignoreDiacritics
                    rows={rows}
                    columns={columns}
                    disableColumnMenu
                    hideFooterSelectedRowCount
                    sortingOrder={['desc', 'asc']}
                    autoHeight={true}
                    getRowHeight={() => 'auto'}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: 0,
                                pageSize: pageSize,
                            },
                        },
                        sorting: {
                            sortModel: [{
                                field: localStorage.getItem('measurementsSortField') || 'date',
                                sort: localStorage.getItem('measurementsSortDirection') || 'desc',
                            }],
                        },
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: filterValues,
                            },
                        },
                    }}
                    pageSizeOptions={pageSizeOptions}
                    onPaginationModelChange={setPaginationModel}
                    onSortModelChange={setSortModel}
                    onFilterModelChange={setFilterModel}
                    slots={{ toolbar: QuickSearchToolbar }}
                />
            </Box>
        );
    }

    getConnectedStyle(available) {
        return {
            color: available ? undefined : 'red',
            fontWeight: available ? undefined : 'bold',
        };
    }

    getServerStatus() {
        var connected = this.state.isConnected === true;
        if (connected)
            return;

        return (
            <Box>
                {'Server: '}
                <span style={this.getConnectedStyle(connected)}>
                    {(connected ? 'online' : 'offline')}
                </span>
            </Box>
        )
    }

    render() {
        if (this.state.navigate !== undefined) {
            return (
                <Navigate to={this.state.navigate} />
            )
        }

        if (!this.props.loggedIn)
            return;

        const toggleDrawer = (newOpen) => () => {
            this.setState({ drawerOpen: newOpen });
        };

        const onDrawerClick = (event) => {
            var dest = getNavigation(event.target.innerText, window.location.pathname);
            this.setState({
                drawerOpen: false,
                navigate: dest
            });
        };

        const onToggleShowRemovedMeasurements = (event) => {
            localStorage.setItem('measurementsShowRemoved', event.target.checked ? 1 : 0);
            this.setState({ showRemovedMeasurements: event.target.checked });
        };

        return (
            <Box sx={{ padding: '0.5rem' }}>
                <ThemeProvider theme={this.defaultTheme}>
                    <CssBaseline />
                    <Drawer open={this.state.drawerOpen} onClose={toggleDrawer(false)}>
                        <Box sx={{ width: 250 }} role='presentation'>
                            <List>
                                {getPages().map((text) => (
                                    <ListItem key={text} disablePadding>
                                        <ListItemButton onClick={onDrawerClick}>
                                            <ListItemText primary={text} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                                <Divider component='li' />
                                <ListItem key={'show-measurements'}>
                                    <FormControlLabel
                                        label="Show hidden measurements"
                                        control={
                                            <Checkbox
                                                checked={this.state.showRemovedMeasurements}
                                                onChange={onToggleShowRemovedMeasurements}
                                            />
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>
                    <Box>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '20%' }} className='text-left'><Button onClick={toggleDrawer(true)} startIcon={<MenuIcon />}>Menu</Button></td>
                                    <td style={{ width: '60%' }} className='text-center'><Box sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: '1.5rem' }}>Measurements</Box></td>
                                    <td style={{ width: '20%' }} className='text-right'>{this.getServerStatus()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                    {this.renderMeasurementTable()}
                </ThemeProvider >
            </Box>

        )
    }
}

export default Measurements;
